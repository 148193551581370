/** @jsx jsx */
import React from "react"
import { Card, jsx, Styled, Container, Image, Text, Grid } from "theme-ui"
import Layout from "../components/layout"
import { FcTodoList, FcApproval, FcRating, FcReading } from "react-icons/fc"
import { ImHourGlass } from "react-icons/im"
import { SiBookstack } from "react-icons/si"

const STATUSES = {
  1: { label: "Read", icon: FcApproval, color: "#a7fc00" },
  2: { label: "Reading", icon: FcReading, color: "pink" },
  3: { label: "To Read", icon: FcTodoList, color: "teal" },
}

const Books = [
  {
    image: require("../../static/books/the-pragmatic-programmer.jpeg"),
    status: 1,
    rating: 5,
  },
  {
    image: require("../../static/books/refactoring.jpeg"),
    status: 1,
    rating: 5,
  },
  {
    image: require("../../static/books/effective-python.jpeg"),
    status: 1,
    rating: 5,
  },
  {
    image: require("../../static/books/fluent-python.jpg"),
    status: 1,
    rating: 5,
  },
  {
    image: require("../../static/books/python-distilled.jpeg"),
    status: 2,
    rating: 5,
  },

  {
    image: require("../../static/books/fundamentals-software.jpg"),
    status: 1,
    rating: 3,
  },
  {
    image: require("../../static/books/engineering-at-google.jpg"),
    status: 3,
    rating: 4,
  },
  { image: require("../../static/books/clean-code.jpg"), status: 2, rating: 5 },
  {
    image: require("../../static/books/python-patterns.jpg"),
    status: 1,
    rating: 5,
  },
  {
    image: require("../../static/books/pytest.jpeg"),
    status: 1,
    rating: 5,
  },
  {
    image: require("../../static/books/clean-coder.jpeg"),
    status: 3,
    rating: 4,
  },
  {
    image: require("../../static/books/design-patterns.jpg"),
    status: 1,
    rating: 4,
  },
  {
    image: require("../../static/books/domain-driven-design.jpeg"),
    status: 3,
    rating: 4,
  },
  {
    image: require("../../static/books/release-it.jpeg"),
    status: 3,
    rating: 4,
  },

  {
    image: require("../../static/books/system-design-interview.jpeg"),
    status: 1,
    rating: 5,
  },
  {
    image: require("../../static/books/grokking-algos.jpeg"),
    status: 1,
    rating: 5,
  },
]

function App() {
  const renderIcon = (status) => {
    const Icon = STATUSES[status].icon
    return <Icon />
  }

  return (
    <Layout>
      <Container sx={{ my: 6, textAlign: `center` }}>
        <Styled.h1>
          Books <SiBookstack />
        </Styled.h1>
        <Styled.p>
          Technical books that I have read/reading/plan to read.
        </Styled.p>
        <Grid gap={5} columns={[1, 2, 3, 4]} style={{ "grid-row-gap": "60px" }}>
          {Books.map((book, index) => (
            <Card key={index}>
              <Image src={book.image} style={{ height: 290 }} />
              <div>
                <Text
                  sx={{
                    fontWeight: "bold",
                    display: "inline",
                    paddingRight: "10px",
                  }}
                >
                  Status:
                </Text>
                <span
                  style={{
                    background: STATUSES[book.status].color,
                    color: "black",
                  }}
                >
                  {STATUSES[book.status].label}{" "}
                </span>
                {renderIcon(book.status)}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  sx={{
                    fontWeight: "bold",
                    display: "inline",
                    paddingRight: "10px",
                  }}
                >
                  Rating:
                </Text>
                {book.status == 1 ? (
                  [...Array(book.rating)].map((_, idx) => (
                    <FcRating key={idx} />
                  ))
                ) : (
                  <ImHourGlass />
                )}
              </div>
            </Card>
          ))}
        </Grid>
      </Container>
    </Layout>
  )
}

export default App
